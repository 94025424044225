
.logo-sidebar {
    width: 100%;
    height: 64px;
   
    background-image: url('../assets/images/sidelogo.png');
    background-size: 160px;
    background-position: center center;
    background-repeat: no-repeat;
    
    margin: auto;
  }

  .logo-sidebar-collapsed {
    width: 100%;
    height: 64px;
   
    background-image: url('../assets/images/favicon.png');
    background-size: 50px;
    background-position: center center;
    background-repeat: no-repeat;
    
    margin: auto;
  } 

  .disable-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .hideOnMobile {
    display: none;
  }
  @media only screen and (min-width: 768px) {
    .hideOnMobile {
      display: block;
    }
  }
  
  .hideOnDesktop {
    display: block;
  }
  @media only screen and (min-width: 768px) {
    .hideOnDesktop {
      display: none;
    }
  }
@primary-color: #f0770e;@secondary-color: #ae543a;@layout-sider-background: #fff;@layout-trigger-background: #f0770e;@layout-header-background: #ae543a;@menu-item-font-size: 20px;@menu-icon-size-lg: 20px;@menu-horizontal-line-height: 57.5px;@menu-item-height: 50px;@menu-inline-toplevel-item-height: 50px;@menu-item-padding: 0 25px;@menu-icon-margin-right: 20px;@tooltip-max-width: 300px;