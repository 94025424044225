@import '~antd/dist/antd.less';
.products-main {
    text-align: center;
    min-height: '100vh';
    margin: auto;
  }

  .site-tag-plus {
    background: #fff;
    border-style: dashed;
  }
  .site-tag-plus:disabled {
    background: #ff0000;
    border-style: dashed;
  }
  .edit-tag {
    user-select: none;
  }
  .tag-input {
    width: 78px;
    margin-right: 8px;
    vertical-align: top;
  }
  .tag-disabled {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: white;
    opacity: 0.5;
  }

  .delete-icon {
    color: @primary-color;
    font-size: 20px;
  }

  .ant-drawer-header {
    border-radius: 0;
  }
  .ant-drawer-title {
    color: white;
  }

  .ant-drawer-close, .ant-drawer-close:hover {
    color: white;
  }

  .noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
  }

textarea[name=desc-text-area] {
  resize: none;
  height: 12.5vh !important;
}

.media-label {
  width: 100vh !important;
}

.rec_title {
  font-weight: bold;
}

.rec_desc {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rec_section_title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 0.9em;
  text-align: center;
  padding: 2% 0 !important;
}

.rec_close_btn {
  position: absolute !important;
  right: 5px !important;
  top: 5px !important;
}
@primary-color: #f0770e;@secondary-color: #ae543a;@layout-sider-background: #fff;@layout-trigger-background: #f0770e;@layout-header-background: #ae543a;@menu-item-font-size: 20px;@menu-icon-size-lg: 20px;@menu-horizontal-line-height: 57.5px;@menu-item-height: 50px;@menu-inline-toplevel-item-height: 50px;@menu-item-padding: 0 25px;@menu-icon-margin-right: 20px;@tooltip-max-width: 300px;