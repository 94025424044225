@import '~antd/dist/antd.less';
.products-main {
    text-align: center;
    min-height: '100vh';
    margin: auto;
  }

  .site-tag-plus {
    background: #fff;
    border-style: dashed;
  }
  .edit-tag {
    user-select: none;
  }
  .tag-input {
    width: 78px;
    margin-right: 8px;
    vertical-align: top;
  }

  .delete-icon {
    color: @primary-color;
    font-size: 20px;
  }

  .avatar-uploader > .ant-upload {
    
    height: 20vh;
    width: 100%;
    
  }
  .ant-drawer-header {
    border-radius: 0;
  }
  .ant-drawer-title {
    color: white;
  }

  .ant-drawer-close, .ant-drawer-close:hover {
    color: white;
  }

  /* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
textarea[name=text-area-addr] {
  resize: none;
}
@primary-color: #f0770e;@secondary-color: #ae543a;@layout-sider-background: #fff;@layout-trigger-background: #f0770e;@layout-header-background: #ae543a;@menu-item-font-size: 20px;@menu-icon-size-lg: 20px;@menu-horizontal-line-height: 57.5px;@menu-item-height: 50px;@menu-inline-toplevel-item-height: 50px;@menu-item-padding: 0 25px;@menu-icon-margin-right: 20px;@tooltip-max-width: 300px;